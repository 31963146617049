<template>
  <div class="box mb-3" v-show="!isLoading">
    <h1 class="title">Edit Job: {{ jobData.title }}</h1>
    <hr />
    <section>
      <div class="heading mb-4">About Job</div>
      <b-field
        label="*Job title"
        :message="fields.title.message"
        :type="fields.title.class"
      >
        <b-input
          v-model="jobData.title"
          placeholder="Job title"
          type="text"
          @blur="validateJobTitle"
          readonly
        ></b-input>
      </b-field>
      <div class="field is-grouped is-grouped-multiline mb-2 mt-4">
        <div class="control">
          <label class="label">*No Vacancies</label>
          <input
            v-model="jobData.vacancies"
            :class="`input ` + fields.vacancies.class"
            type="number"
            placeholder="No vacancies"
            @blur="validateVacancies"
          />
          <p class="help is-danger" v-if="fields.vacancies.message">
            {{ fields.vacancies.message }}
          </p>
        </div>
        <div class="control">
          <label class="label">Reference No</label>
          <input
            v-model="jobData.ref_no"
            class="input"
            type="text"
            placeholder="Ref/iul number (optional)"
          />
        </div>
      </div>

      <b-field
        label="*Tag line"
        :message="fields.summary.message"
        :type="fields.summary.class"
      >
        <b-input
          v-model="jobData.summary"
          maxlength="170"
          type="textarea"
          placeholder="This will be shown at the job list page"
          rows="3"
          @blur="validateSummary"
        ></b-input>
      </b-field>
      <b-field
        label="*Job description"
        :message="fields.description.message"
        :type="fields.description.class"
      >
        <Editor
          apiKey="g35f0829ufytkkshjqms46bopkuss68dsr31rvp5utvadejb"
          v-model="jobData.description"
          @blur="validateDescription"
          :init="{
            menubar: false,
            inline: false,
            autoresize_bottom_margin: 0,
            max_height: 450,
            min_height: 350,
            min_width: '100%',
            placeholder: 'Job description (required)',
            selector: 'textarea',
            toolbar_location: 'bottom',
            branding: false,
            plugins: [
              'advlist autolink lists link image charmap print preview anchor',
              'searchreplace visualblocks code fullscreen',
              'insertdatetime media table paste code help wordcount',
              'autoresize',
            ],
            toolbar:
              'formatselect | bold italic underline | \
           alignleft aligncenter alignright alignjustify | \
           bullist numlist outdent indent | removeformat | link | help',
          }"
        />
      </b-field>
      <b-field
        label="Link to visit"
        :message="fields.ref_link.message"
        :type="fields.ref_link.class"
      >
        <b-input
          ref="ref_link"
          v-model="jobData.ref_link"
          placeholder="Link to a page for further details of the job (optional)"
          type="url"
          @blur="validateRefLink"
        ></b-input>
      </b-field>
      <hr />
      <div class="heading mb-4">Application details</div>
      <b-field
        label="*How to apply"
        :message="fields.how_to_apply.message"
        :type="fields.how_to_apply.class"
      >
        <Editor
          apiKey="g35f0829ufytkkshjqms46bopkuss68dsr31rvp5utvadejb"
          v-model="jobData.how_to_apply"
          @blur="validateHowToApply"
          :init="{
            menubar: false,
            inline: false,
            autoresize_bottom_margin: 0,
            max_height: 450,
            min_height: 350,
            min_width: '100%',
            placeholder:
              'State how candidates can apply for the job (required)',
            selector: 'textarea',
            toolbar_location: 'bottom',
            branding: false,
            plugins: [
              'advlist autolink lists link image charmap print preview anchor',
              'searchreplace visualblocks code fullscreen',
              'insertdatetime media table paste code help wordcount',
              'autoresize',
            ],
            toolbar:
              'formatselect | bold italic underline | \
           alignleft aligncenter alignright alignjustify | \
           bullist numlist outdent indent | removeformat | link | help',
          }"
        />
      </b-field>

      <b-field
        label="*Application email"
        :message="fields.application_email.message"
        :type="fields.application_email.class"
      >
        <b-input
          v-model="jobData.application_email"
          placeholder="Email address to which applications will be sent"
          type="email"
          maxlength="50"
          @blur="validateApplicationEmail"
        >
        </b-input>
      </b-field>

      <div class="field is-grouped is-grouped-multiline">
        <b-field
          label="*Deadline to apply"
          grouped
          :type="{ 'is-danger': deadline.error }"
          :message="deadline.message"
        >
          <b-datepicker
            v-model="jobData.deadline"
            :min-date="getYesterday"
            @blur="deadlineValid"
          >
            <template v-slot:trigger>
              <b-button
                icon-left="calendar-today"
                :type="deadline.class"
                @blur="deadlineValid"
              />
            </template>
          </b-datepicker>
          <b-input
            expanded
            readonly
            :value="selectedString"
            placeholder="Deadline"
          />
        </b-field>
      </div>

      <hr class="mt-6" />
      <div class="heading mb-3">Job specifications</div>
      <div class="field is-grouped is-grouped-multiline">
        <div class="control mb-5">
          <label class="label">*Job Type</label>
          <div :class="`select ` + fields.job_type.class">
            <select
              v-model="jobData.job_type"
              @blur="validateJobType"
              @change="validateJobType"
            >
              <option v-for="t in jobTypes" :key="t.value" :value="t.value">
                {{ t.text }}
              </option>
            </select>
            <p class="help is-danger">{{ fields.job_type.message }}</p>
          </div>
        </div>
        <div class="control mb-5">
          <label class="label">*Job Location</label>
          <div :class="`select ` + fields.location.class">
            <select
              v-model="jobData.location"
              @blur="validateJobLocation"
              @change="validateJobLocation"
            >
              <option v-for="i in jobLocations" :key="i.value" :value="i.value">
                {{ i.text }}
              </option>
            </select>
            <p class="help is-danger">{{ fields.location.message }}</p>
          </div>
        </div>
        <div class="control mb-5">
          <label class="label">*Job sector</label>
          <div :class="`select ` + fields.sector.class">
            <select
              v-model="jobData.sector"
              @blur="validateJobSector"
              @change="validateJobSector"
            >
              <option
                v-for="sec in jobSectors"
                :key="sec.value"
                :value="sec.value"
              >
                {{ sec.text }}
              </option>
            </select>
            <p class="help is-danger">{{ fields.sector.message }}</p>
          </div>
        </div>
        <div class="control mb-5">
          <label class="label">*Job Category</label>
          <div :class="`select ` + fields.category.class">
            <select
              v-model="jobData.category"
              @blur="validateJobCategory"
              @change="validateJobCategory"
            >
              <option
                v-for="cat in jobCategories"
                :key="cat.value"
                :value="cat.value"
              >
                {{ cat.text }}
              </option>
            </select>
            <p class="help is-danger">{{ fields.category.message }}</p>
          </div>
        </div>
        <div class="control mb-5">
          <label class="label">*Min. Qualification</label>
          <div :class="`select ` + fields.qualification.class">
            <select
              v-model="jobData.min_qualification"
              @blur="validateJobQualification"
              @change="validateJobQualification"
            >
              <option
                v-for="q in jobQualifications"
                :key="q.value"
                :value="q.value"
              >
                {{ q.text }}
              </option>
            </select>
            <p class="help is-danger">{{ fields.qualification.message }}</p>
          </div>
        </div>
        <div class="control mb-5">
          <label class="label">*Min. Experience</label>
          <div :class="`select ` + fields.experience.class">
            <select
              v-model="jobData.min_experience"
              @blur="validateJobExperience"
              @change="validateJobExperience"
            >
              <option
                v-for="e in jobExperiences"
                :key="e.value"
                :value="e.value"
              >
                {{ e.text }}
              </option>
            </select>
            <p class="help is-danger">{{ fields.experience.message }}</p>
          </div>
        </div>
        <div class="control mb-5">
          <label class="label">*Salary range</label>
          <div :class="`select ` + fields.salary.class">
            <select
              v-model="jobData.salary_range"
              @blur="validateJobSalary"
              @change="validateJobSalary"
            >
              <option
                v-for="sal in jobSalaryRanges"
                :key="sal.value"
                :value="sal.value"
              >
                {{ sal.text }}
              </option>
            </select>
            <p class="help is-danger">{{ fields.salary.message }}</p>
          </div>
        </div>
      </div>

      <hr />
      <div class="heading mb-3">Attachment</div>
      <b-field label="Included filename">
        <b-field class="file is-primary" :class="{ 'has-name': !!file }">
          <b-upload
            v-model="file"
            class="file-label"
            accept=".pdf,.jpeg,.png,.jpg"
            rounded
          >
            <span class="file-cta">
              <b-icon class="file-icon" icon="upload"></b-icon>
              <span class="file-label">{{
                file.name || "Click to upload"
              }}</span>
            </span>
          </b-upload>
        </b-field>
      </b-field>

      <!-- <hr />
      <div class="heading mb-3">Add Skills</div>
      <b-field label="Add skills">
        <b-taginput
          v-model="jobData.skills"
          :data="filteredSkills"
          autocomplete
          :allow-new="allowNew"
          :open-on-focus="openOnFocus"
          maxtags="5"
          field="text"
          icon="label"
          placeholder="Add a skill (optional)"
          @typing="getFilteredSkills"
        >
        </b-taginput>
      </b-field>
      {{ jobData.skills }} -->

      <hr class="mt-6" />
      <p class="mb-4">
        <strong> Note:</strong>
        Once you click save, the job will be saved to draft jobs. You can
        publish the job from there.
      </p>
      <div class="field is-grouped mb-3">
        <p class="control">
          <a class="button is-primary" @click.prevent="createJob"> Save </a>
        </p>
        <p class="control">
          <a class="button is-light" @click.prevent="returnBack"> Cancel </a>
        </p>
      </div>
    </section>
  </div>
</template>

<script>
import Editor from "@tinymce/tinymce-vue";
import axios from "axios";

export default {
  components: {
    Editor,
  },
  data() {
    return {
      jobData: {
        id: "",
        title: "",
        slug: "slug",
        ref_no: "",
        job_type: "",
        vacancies: null,
        location: null,
        sector: null,
        category: null,
        min_qualification: null,
        min_experience: null,
        salary_range: null,
        //skills: [],
        deadline: null,
        summary: "",
        description: "",
        how_to_apply: "",
        ref_link: "",
        application_email: "",
        attachment: null,
        status: "",
        employer_id: "",
      },
      jobTypes: [],
      jobLocations: [],
      jobSectors: [],
      jobCategories: [],
      jobQualifications: [],
      jobExperiences: [],
      jobSalaryRanges: [],
      jobSkills: [],
      filteredSkills: [],
      file: {},
      selected: null,
      isSelectOnly: false,
      allowNew: false,
      openOnFocus: true,
      isLoading: false,
      deadline: {
        class: "is-primary",
        error: false,
        message: "",
      },
      fields: {
        title: {
          class: "",
          error: false,
          message: "Important! Job title cannot be changed once job is created",
        },
        vacancies: {
          class: "",
          error: false,
          message: "",
        },
        summary: {
          class: "",
          error: false,
          message: "",
        },
        description: {
          class: "",
          error: false,
          message: "",
        },
        ref_link: {
          class: "",
          error: false,
          message: "",
        },
        how_to_apply: {
          class: "",
          error: false,
          message: "",
        },
        application_email: {
          class: "",
          error: false,
          message: "",
        },
        job_type: {
          class: "",
          error: false,
          message: "",
        },
        location: {
          class: "",
          error: false,
          message: "",
        },
        sector: {
          class: "",
          error: false,
          message: "",
        },
        category: {
          class: "",
          error: false,
          message: "",
        },
        qualification: {
          class: "",
          error: false,
          message: "",
        },
        experience: {
          class: "",
          error: false,
          message: "",
        },
        salary: {
          class: "",
          error: false,
          message: "",
        },
      },
    };
  },
  computed: {
    selectedString() {
      return this.jobData.deadline ? this.jobData.deadline.toDateString() : "";
    },
    getYesterday() {
      return new Date(new Date().getTime() - 86400000);
    },
  },
  methods: {
    getEmployer() {
      this.jobData.employer_id =
        this.$store.getters["employers/getEmployer"].id;
    },
    async fetchJob(id) {
      await axios
        .get(`/api/jobs/${id}/`)
        .then((res) => {
          this.jobData.id = res.data.id;
          this.jobData.title = res.data.title;
          this.jobData.slug = res.data.slug;
          this.jobData.job_type = res.data.job_type.id;
          this.jobData.vacancies = res.data.vacancies;
          this.jobData.location = res.data.location.id;
          this.jobData.sector = res.data.sector.id;
          this.jobData.category = res.data.category.id;
          this.jobData.min_qualification = res.data.min_qualification.id;
          this.jobData.min_experience = res.data.min_experience.id;
          this.jobData.salary_range = res.data.salary.id;
          this.jobData.deadline = new Date(res.data.deadline);
          this.jobData.summary = res.data.summary;
          this.jobData.description = res.data.description;
          this.jobData.how_to_apply = res.data.how_to_apply;
          this.jobData.application_email = res.data.application_email;
          this.jobData.attachment = res.data.attachment;
          this.jobData.employer_id = res.data.employer.id;

          //update job status if published
          if (res.data.status === "1") {
            this.jobData.status = "3";
          } else {
            this.jobData.status = res.data.status;
          }

          //optional feilds
          if (res.data.ref_no) {
            this.jobData.ref_no = res.data.ref_no;
          }
          if (res.data.ref_link) {
            this.jobData.ref_link = res.data.ref_link;
          }
          // add job skills
          // if (res.data.skills) {
          //   res.data.skills.forEach((skill) => {
          //     this.jobData.skills.push({
          //       text: skill.name,
          //       value: skill.id,
          //     });
          //   });
          // }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async fectchJobTypes() {
      const response = await axios.get("/api/jobtypes/");
      response.data.forEach((jobType) => {
        this.jobTypes.push({
          text: jobType.name,
          value: jobType.id,
        });
      });
    },
    async fectchJobLocations() {
      const response = await axios.get("/api/locations/");
      response.data.forEach((loc) => {
        this.jobLocations.push({
          text: loc.name,
          value: loc.id,
        });
      });
    },
    async fectchJobSectors() {
      const response = await axios.get("/api/sectors/");
      response.data.forEach((sector) => {
        this.jobSectors.push({
          text: sector.name,
          value: sector.id,
        });
      });
    },
    async fectchJobCategories() {
      const response = await axios.get("/api/categories/");
      response.data.forEach((category) => {
        this.jobCategories.push({
          text: category.name,
          value: category.id,
        });
      });
    },
    async fectchJobQualifications() {
      const response = await axios.get("/api/minqualifications/");
      response.data.forEach((qualification) => {
        this.jobQualifications.push({
          text: qualification.name,
          value: qualification.id,
          order: qualification.order,
        });
      });
      // order array by order
      this.jobQualifications.sort((a, b) => {
        return a.order - b.order;
      });
    },
    async fectchJobExperiences() {
      const response = await axios.get("/api/minexperiences/");
      response.data.forEach((experience) => {
        this.jobExperiences.push({
          text: experience.name,
          value: experience.id,
          order: experience.order,
        });
      });
      // order array by order
      this.jobExperiences.sort((a, b) => {
        return a.order - b.order;
      });
    },
    async fectchJobSalaryRanges() {
      const response = await axios.get("/api/salaryranges/");
      response.data.forEach((salaryRange) => {
        this.jobSalaryRanges.push({
          text: salaryRange.name,
          value: salaryRange.id,
          order: salaryRange.order,
        });
      });
      // order array by salaryRange.order
      this.jobSalaryRanges.sort((a, b) => a.order - b.order);
    },
    async fectchJobSkills() {
      const response = await axios.get("/api/skills/");
      response.data.forEach((skill) => {
        this.jobSkills.push({
          text: skill.name,
          value: skill.id,
        });
        this.filteredSkills.push({
          text: skill.name,
          value: skill.id,
        });
      });
    },
    getFilteredSkills(text) {
      this.filteredSkills = this.jobSkills.filter((option) => {
        return (
          option.text.toString().toLowerCase().indexOf(text.toLowerCase()) >= 0
        );
      });
    },
    returnBack() {
      this.$router.push({ name: "EmployerJobs" });
    },
    deadlineValid() {
      if (this.jobData.deadline) {
        this.deadline.class = "is-primary";
        this.deadline.error = false;
        this.deadline.message = "";
        return true;
      } else {
        this.deadline.class = "is-danger";
        this.deadline.error = true;
        this.deadline.message = "Deadline is required";
        return false;
      }
    },
    validateJobTitle() {
      if (this.jobData.title) {
        this.fields.title.class = "";
        this.fields.title.error = false;
        this.fields.title.message =
          "Important! Job title cannot be changed once job is created";
        return true;
      } else {
        this.fields.title.class = "is-danger";
        this.fields.title.error = true;
        this.fields.title.message = "Job title is required";
        return false;
      }
    },
    validateVacancies() {
      if (this.jobData.vacancies) {
        if (this.jobData.vacancies > 0) {
          this.fields.vacancies.class = "";
          this.fields.vacancies.error = false;
          this.fields.vacancies.message = "";
          return true;
        } else {
          this.fields.vacancies.class = "is-danger";
          this.fields.vacancies.error = true;
          this.fields.vacancies.message = "Vacancies must be greater than 0";
          return false;
        }
      } else {
        this.fields.vacancies.class = "is-danger";
        this.fields.vacancies.error = true;
        this.fields.vacancies.message = "Vacancies is required";
        return false;
      }
    },
    validateSummary() {
      if (this.jobData.summary) {
        this.fields.summary.class = "";
        this.fields.summary.error = false;
        this.fields.summary.message = "";
        return true;
      } else {
        this.fields.summary.class = "is-danger";
        this.fields.summary.error = true;
        this.fields.summary.message = "Tag line is required";
        return false;
      }
    },
    validateDescription() {
      if (this.jobData.description) {
        this.fields.description.class = "";
        this.fields.description.error = false;
        this.fields.description.message = "";
        return true;
      } else {
        this.fields.description.class = "is-danger";
        this.fields.description.error = true;
        this.fields.description.message = "Description is required";
        return false;
      }
    },
    validateUrl(string) {
      let url;

      try {
        url = new URL(string);
      } catch (_) {
        return false;
      }

      return url.protocol === "http:" || url.protocol === "https:";
    },
    validateRefLink() {
      if (this.jobData.ref_link) {
        if (this.validateUrl(this.jobData.ref_link)) {
          this.fields.ref_link.class = "";
          this.fields.ref_link.error = false;
          this.fields.ref_link.message = "";
          return true;
        } else {
          this.fields.ref_link.class = "is-danger";
          this.fields.ref_link.error = true;
          this.fields.ref_link.message =
            "Please enter a valid URL. eg. https://www.iulaanu.com";
          return false;
        }
      } else {
        this.fields.ref_link.class = "";
        this.fields.ref_link.error = false;
        this.fields.ref_link.message = "";
        return true;
      }
    },
    validateHowToApply() {
      if (this.jobData.how_to_apply) {
        this.fields.how_to_apply.class = "";
        this.fields.how_to_apply.error = false;
        this.fields.how_to_apply.message = "";
        return true;
      } else {
        this.fields.how_to_apply.class = "is-danger";
        this.fields.how_to_apply.error = true;
        this.fields.how_to_apply.message = "How to apply is required";
        return false;
      }
    },
    validateApplicationEmail() {
      if (this.jobData.application_email) {
        // check if the entered text is an email
        if (
          this.jobData.application_email.match(
            /^\w+([\\.-]?\w+)*@\w+([\\.-]?\w+)*(\.\w{2,3})+$/
          )
        ) {
          this.fields.application_email.class = "";
          this.fields.application_email.error = false;
          this.fields.application_email.message = "";
          return true;
        } else {
          this.fields.application_email.class = "is-danger";
          this.fields.application_email.error = true;
          this.fields.application_email.message =
            "Please enter a valid email address";
          return false;
        }
      } else {
        this.fields.application_email.class = "is-danger";
        this.fields.application_email.error = true;
        this.fields.application_email.message = "Application email is required";
        return false;
      }
    },
    validateJobType() {
      if (this.jobData.job_type) {
        this.fields.job_type.class = "";
        this.fields.job_type.error = false;
        this.fields.job_type.message = "";
        return true;
      } else {
        this.fields.job_type.class = "is-danger";
        this.fields.job_type.error = true;
        this.fields.job_type.message = "Job type is required";
        return false;
      }
    },
    validateJobLocation() {
      if (this.jobData.location) {
        this.fields.location.class = "";
        this.fields.location.error = false;
        this.fields.location.message = "";
        return true;
      } else {
        this.fields.location.class = "is-danger";
        this.fields.location.error = true;
        this.fields.location.message = "Job location is required";
        return false;
      }
    },
    validateJobSector() {
      if (this.jobData.sector) {
        this.fields.sector.class = "";
        this.fields.sector.error = false;
        this.fields.sector.message = "";
        return true;
      } else {
        this.fields.sector.class = "is-danger";
        this.fields.sector.error = true;
        this.fields.sector.message = "Job sector is required";
        return false;
      }
    },
    validateJobCategory() {
      if (this.jobData.category) {
        this.fields.category.class = "";
        this.fields.category.error = false;
        this.fields.category.message = "";
        return true;
      } else {
        this.fields.category.class = "is-danger";
        this.fields.category.error = true;
        this.fields.category.message = "Job category is required";
        return false;
      }
    },
    validateJobQualification() {
      if (this.jobData.min_qualification) {
        this.fields.qualification.class = "";
        this.fields.qualification.error = false;
        this.fields.qualification.message = "";
        return true;
      } else {
        this.fields.qualification.class = "is-danger";
        this.fields.qualification.error = true;
        this.fields.qualification.message = "Job qualification is required";
        return false;
      }
    },
    validateJobExperience() {
      if (this.jobData.min_experience) {
        this.fields.experience.class = "";
        this.fields.experience.error = false;
        this.fields.experience.message = "";
        return true;
      } else {
        this.fields.experience.class = "is-danger";
        this.fields.experience.error = true;
        this.fields.experience.message = "Job experience is required";
        return false;
      }
    },
    validateJobSalary() {
      if (this.jobData.salary_range) {
        this.fields.salary.class = "";
        this.fields.salary.error = false;
        this.fields.salary.message = "";
        return true;
      } else {
        this.fields.salary.class = "is-danger";
        this.fields.salary.error = true;
        this.fields.salary.message = "Job salary range is required";
        return false;
      }
    },
    formValid() {
      let error = 0;
      if (!this.validateJobTitle()) {
        error++;
      }
      if (!this.validateVacancies()) {
        error++;
      }
      if (!this.validateSummary()) {
        error++;
      }
      if (!this.validateDescription()) {
        error++;
      }
      if (!this.validateRefLink()) {
        error++;
      }
      if (!this.validateHowToApply()) {
        error++;
      }
      if (!this.validateApplicationEmail()) {
        error++;
      }
      if (!this.deadlineValid()) {
        error++;
      }
      if (!this.validateJobType()) {
        error++;
      }
      if (!this.validateJobLocation()) {
        error++;
      }
      if (!this.validateJobSector()) {
        error++;
      }
      if (!this.validateJobCategory()) {
        error++;
      }
      if (!this.validateJobQualification()) {
        error++;
      }
      if (!this.validateJobExperience()) {
        error++;
      }
      if (!this.validateJobSalary()) {
        error++;
      }

      if (error > 0) {
        this.$buefy.toast.open({
          message: "Please correct the errors in the form to continue",
          type: "is-danger",
          duration: 3000,
        });
        return false;
      } else {
        return true;
      }
    },
    async submitForm() {
      this.$store.dispatch("setLoading", true);

      const formData = new FormData();
      formData.append("title", this.jobData.title);
      formData.append("slug", this.jobData.slug);
      formData.append("ref_no", this.jobData.ref_no);
      formData.append("job_type", this.jobData.job_type);
      formData.append("vacancies", this.jobData.vacancies);
      formData.append("location", this.jobData.location);
      formData.append("sector", this.jobData.sector);
      formData.append("category", this.jobData.category);
      formData.append("min_qualification", this.jobData.min_qualification);
      formData.append("min_experience", this.jobData.min_experience);
      formData.append("salary", this.jobData.salary_range);
      formData.append("summary", this.jobData.summary);
      formData.append("description", this.jobData.description);
      formData.append("how_to_apply", this.jobData.how_to_apply);
      formData.append("ref_link", this.jobData.ref_link);
      formData.append("application_email", this.jobData.application_email);
      formData.append("status", this.jobData.status);
      formData.append("employer", this.jobData.employer_id);

      //format date and append to form data
      const date = new Date(this.jobData.deadline);
      const dateString =
        date.getFullYear() +
        "-" +
        (date.getMonth() + 1) +
        "-" +
        date.getDate() +
        " " +
        date.getHours() +
        ":" +
        date.getMinutes() +
        ":" +
        date.getSeconds();
      formData.append("deadline", dateString);

      //add job skills
      // this.jobData.skills.forEach((skill) => {
      //   formData.append("skills", skill.value);
      // });

      if (this.file.name) {
        formData.append("attachement", this.file);
      }

      await axios
        .patch("/api/jobs/" + this.$route.params.id + "/", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(
          (response) => {
            if (response.status === 200) {
              let emp = this.$store.getters["employers/getEmployer"];

              this.$store.dispatch("jobs/fetchJobs", emp.id).then(() => {
                this.$store.dispatch("setLoading", false);
                this.$router.push({ name: "EmployerJobs" });
                this.$buefy.toast.open({
                  message:
                    "Job changed successfully. Changes will be published after review",
                  type: "is-success",
                  queue: false,
                  closeOnClick: false,
                  duration: 5000,
                });
              });
            } else {
              this.$store.dispatch("setLoading", false);
              this.$buefy.toast.open({
                message: "Something went wrong. Please try again.",
                type: "is-danger",
                queue: false,
                duration: 2500,
              });
            }
          },
          (error) => {
            window.scrollTo(0, 0);
            this.$store.dispatch("setLoading", false);
            console.log(error);
            let errors = error.response.data;
            let i;
            for (i in errors) {
              this.fields[i].class = "is-danger";
              this.fields[i].error = true;
              this.fields[i].message = errors[i][0];
              this.$buefy.toast.open({
                message: errors[i][0],
                type: "is-danger",
                queue: false,
                duration: 2500,
              });
            }
          }
        );
    },
    createJob() {
      if (this.formValid()) {
        this.submitForm();
      }
    },
  },
  created() {
    // set loading
    this.$store.dispatch("setLoading", true);
    this.isLoading = true;

    //fetch job data
    this.fectchJobTypes().then(() => {
      this.fectchJobLocations().then(() => {
        this.fectchJobSectors().then(() => {
          this.fectchJobCategories().then(() => {
            this.fectchJobQualifications().then(() => {
              this.fectchJobExperiences().then(() => {
                this.fectchJobSalaryRanges().then(() => {
                  this.fectchJobSkills().then(() => {
                    this.fetchJob(this.$route.params.id).then(() => {
                      this.getEmployer();
                      //unset loading
                      this.$store.dispatch("setLoading", false);
                      this.isLoading = false;
                    });
                  });
                });
              });
            });
          });
        });
      });
    });
  },
};
</script>

<style>
.my_class {
  border: 1px solid red;
}
</style>
